
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      isAdded: false
    }
  },
  computed: {
    ...mapState(['location']),
    images() {
      return (this.product && this.product.images) ? this.product.images : null
    },
    imageUrl() {
      if (this.images) {
        if (this.images && this.images['marketplace-product']) {
          return this.images['marketplace-product'].url
        }
        if (this.images && this.images.default) {
          return this.images.default.url
        }
      }
      return ''
    },
    isDefaultMenu() {
      return this.location ? false : true
    },
    correctedNutritionLifestyle() {
      // Handle typo, nutrition_lifestyle can come as 'nutrition_lifestyle ', with a trailing space
      return this.product.nutrition_lifestyle || this.product['nutrition_lifestyle '];
    },
    productTagClass() {
      if (!this.product.product_tag) {
        return ''
      }

      if (this.product.color) {
        return `${this.product.color}-tag`
      }

      return 'default-tag'
    },
    productNameSize() {
      const isMobile =  this.$mq == "sm" || this.$mq == "md"
      const mobileSize = 18
      const desktopSize = 30

      const preferredSize = isMobile ? mobileSize : desktopSize
      const nameLength = this.product.name.length

      if (isMobile) {
        if (nameLength > 24) {
          return preferredSize * 0.8
        } else if (nameLength > 30) {
          return preferredSize * 0.7
        }
      }

      if (nameLength > 26) {
        return preferredSize * 0.8
      } else if (nameLength > 32) {
        return preferredSize * 0.6
      }

      return preferredSize
    }
  },
  methods: {
    ...mapMutations({
      setShowCart: 'setShowCart',
      setLoading: 'setLoading',
      setProduct: 'product/setProduct',
    }),
    ...mapActions({
      sendRequest: 'sendRequest',
      quickAddProduct: 'product/quickAddProduct',
      processMods: 'product/processMods',
    }),
    async checkQuickAdd(method) {
      const product = this.product

      if (!this.product.quickadd) {
        this.$router.push('/menu/' + this.location.menu_id + '/product/' + this.product.id)
        return
      }

      if (!this.location) {
        this.$router.push('/order')
        return
      }

      this.setProduct(null)
      this.setLoading(true)
      const config = {
        method: 'get',
        url: `menus/${this.location.menu_id}/products/${product.id}`
      }
      const response = await this.sendRequest(config)
      if (response.status === 'ok') {
        const requiredModFound = response.data.mods.find(mod => {
          if (mod.selects.mandatory) {
            return true
          } else {
            return mod.options.find(option => {
              return option.mods.find(subMod => {
                if (subMod.selects.mandatory) {
                  return true
                } else {
                  return subMod.options.find(subOption => {
                    return subOption.mods.find(subSubMod => {
                      if (subSubMod.selects.mandatory) {
                        return true
                      } else {
                        return false
                      }
                    })
                  })
                }
              })
            })
          }
        })
        this.setLoading(false)

        if (!requiredModFound && method === 'direct') {
          return this.quickAdd(product)
        } else {
          this.setProduct(response.data)
          await this.processMods()
          document.body.style.overflow = 'hidden'
          this.$emit('showModal')
        }
      } else {
        console.log('Not found')
      }
      this.setLoading(false)

      if (this.location) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: "select_item",
          location_code: this.location?.id,
          location_name: this.location?.name,
          items: [{
            item_id: this.product.id,
            item_name: this.product.name,
          }]
        })
      }
    },
    async quickAdd(product) {
      const resp = await this.quickAddProduct(product)
      if (resp) {
        document.body.style.overflow = 'hidden'
        this.setShowCart(true)

        this.isAdded = true

        setTimeout(() => {
          this.isAdded = false
        }, 5000)
      }
    },
    calories(product) {
      let base = product.nutrition?.calories?.base
      let max = product.nutrition?.calories?.max
      // if (base) {
      //   base = base.toString().replace('-','')
      // }
      // if (max) {
      //   max = max.toString().replace('-','')
      // }
      return (product.cost && base !== null  ? ' | ' : '') + (base !== null ? base : '') + (max !== null ? ' - '+max : '') + (base !== null ? ' Cal' : '')
    },
  }
}
